<template>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    "
  >
    <details
      v-if="selectedClass.classID === 'admin'"
      style="margin: 20px; width: 100%"
    >
      <summary>QR коды</summary>
      <div style="display: flex; flex-direction: column; flex-wrap: wrap">
        <div class="control-buttons">
          <v-btn
            @click="createINV()"
            target="_blank"
            style="color: white; background-color: #0050b8; margin: 10px"
            text
          >
            Создать пустой QR
          </v-btn>
          <v-btn
            @click="createManyINV()"
            target="_blank"
            style="color: white; background-color: #004196; margin: 10px"
            text
          >
            Создать несколько пустых QR
          </v-btn>
          <v-btn
            @click="printQR()"
            target="_blank"
            style="color: white; background-color: #9f9f9f; margin: 10px"
            text
          >
            Печать QR
          </v-btn>
          <v-btn
            @click="printQRselect()"
            target="_blank"
            style="color: white; background-color: #9f9f9f; margin: 10px"
            text
          >
            Печать только выбранных QR
          </v-btn>

          <v-btn
            @click="printManyQRselect()"
            target="_blank"
            style="color: white; background-color: #9f9f9f; margin: 10px"
            text
          >
            Печать нескольких одинаковых копий выбранного QR
          </v-btn>
          <v-btn
            @click="deleteSelect()"
            target="_blank"
            style="color: white; background-color: #790000; margin: 10px"
            text
          >
            Удалить выбранные
          </v-btn>
        </div>
        <div>
          <div>Свободные QR коды:</div>
          <div class="inv_main_qr_list" id="QR">
            <div
              v-for="(el, index) in invNotUsedList"
              :key="index"
              style="border: solid black 1px; padding: 2px; margin: 2px"
              @click="selectQR(el)"
              :class="{
                selectClass: el.select,
                unselect: !el.select,
              }"
              :id="el._id"
            >
              <div>
                <div style="text-align: center">{{ el.comment }}</div>
                <VueQRCodeComponent
                  style="padding: 12px"
                  :text="el._id"
                  :size="140"
                  color="#000000"
                  bg-color="#ffffff"
                  error-level="H"
                ></VueQRCodeComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </details>
    <details style="margin: 20px; width: 100%">
      <summary>Таблица на кабинет</summary>
      <div v-if="invList.length > 0">
        <div
          class="form-group"
          style="
            width: 90%;
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          "
        >
          <label for="exampleFormControlSelect1" style="margin-top: 15px"
            >Выбрать кабинет: &nbsp;
          </label>
          <select
            class="form-control"
            style="width: 200px; height: 24px; margin-top: 10px"
            v-if="invList.length > 0"
            v-model="selectLocation"
            @change="changeLoc"
          >
            <option value="ВСЕ">Все</option>
            <option
              v-for="(el, index) in filterByLocation"
              :key="index"
              :value="el"
            >
              {{ el.location }} ({{ el.school }})
            </option>
          </select>
          <v-btn
            @click="printTabKab()"
            target="_blank"
            style="color: white; background-color: #9f9f9f; margin: 10px"
            text
          >
            Печать
          </v-btn>

          <v-btn
            @click="openGroupOperation()"
            target="_blank"
            style="color: white; background-color: #5f5f5f; margin: 10px"
            text
            v-if="selectedClass.classID === 'admin'"
          >
            Групповые операции
          </v-btn>
        </div>
        <table style="margin-left: 70px" id="tab_kab">
          <tr>
            <th>
              <input
                type="checkbox"
                @change="selectAllGpOperations"
                style="margin: 5px; width: 20px; height: 20px"
              />
            </th>
            <th>№</th>
            <th>Наименование</th>
            <th>Инв. №</th>
            <th>Нахождение</th>
            <th>Группа</th>
            <th>Школа</th>
            <th>Федеральная программа</th>
            <th>Статус</th>
            <th>S/N</th>
            <th>Количество</th>
            <th>Комментарий</th>
          </tr>
          <tr v-for="(el, index) in printList" :key="index">
            <td>
              <input
                type="checkbox"
                v-model="el.selectGroupOperation"
                style="margin: 5px; width: 20px; height: 20px"
              />
            </td>
            <td>{{ index + 1 }}</td>
            <td>{{ el.name }}</td>
            <td>{{ el.schoolID }}</td>
            <td>{{ el.location }}</td>
            <td>{{ el.group }}</td>
            <td>{{ el.school }}</td>
            <td>{{ el.federalProgram }}</td>
            <td>{{ el.status }}</td>
            <td>{{ el.serialNumber }}</td>
            <td>{{ el.quantity }}</td>
            <td>{{ el.comment }}</td>
          </tr>
        </table>
      </div>
    </details>
    <div style="margin: 40px; width: 96%">
      <div class="control-buttons">
        <button
          style="margin-left: 7px"
          class="btn btn-success"
          @click="getINV()"
        >
          Обновить
        </button>
        <button
          style="margin-left: 7px"
          class="btn btn-dark"
          @click="exportData()"
        >
          ЭКСПОРТ ДАННЫХ
        </button>

        <button
          style="margin-left: 7px"
          class="btn btn-info"
          @click="infoShow = !infoShow"
        >
          Инструкции
        </button>
      </div>

      <div>
        <v-col>
          <v-text-field
            class="search-table"
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск"
          >
          </v-text-field>
          <v-data-table
            :headers="headers"
            :items="this.invList"
            :items-per-page="5"
            :loading="loading"
            :search="search"
          >
            <template v-slot:[`item.index`]="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div
                style="display: flex; flex-direction: column; font-size: 10px"
              >
                <v-btn
                  @click="updateINVopen(item)"
                  target="_blank"
                  style="
                    color: white;
                    background-color: #0050b8;

                    margin-bottom: 10px;
                    margin-top: 10px;
                  "
                  text
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    enable-background="new 0 0 64 64"
                    height="32px"
                    id="Layer_1"
                    version="1.1"
                    viewBox="0 0 64 64"
                    width="32px"
                    xml:space="preserve"
                    fill="white"
                  >
                    <g>
                      <path
                        d="M55.736,13.636l-4.368-4.362c-0.451-0.451-1.044-0.677-1.636-0.677c-0.592,0-1.184,0.225-1.635,0.676l-3.494,3.484   l7.639,7.626l3.494-3.483C56.639,15.998,56.639,14.535,55.736,13.636z"
                      />
                      <polygon
                        points="21.922,35.396 29.562,43.023 50.607,22.017 42.967,14.39  "
                      />
                      <polygon
                        points="20.273,37.028 18.642,46.28 27.913,44.654  "
                      />
                      <path
                        d="M41.393,50.403H12.587V21.597h20.329l5.01-5H10.82c-1.779,0-3.234,1.455-3.234,3.234v32.339   c0,1.779,1.455,3.234,3.234,3.234h32.339c1.779,0,3.234-1.455,3.234-3.234V29.049l-5,4.991V50.403z"
                      />
                    </g>
                  </svg>
                </v-btn>
              </div>
              <div
                style="display: flex; flex-direction: column; font-size: 10px"
                v-if="selectedClass.classID === 'admin'"
              >
                <v-btn
                  @click="deleteINV(item._id, true)"
                  target="_blank"
                  style="
                    color: white;
                    background-color: brown;
                    margin-bottom: 10px;
                  "
                  text
                >
                  X
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </div>
    </div>

    <v-dialog v-model="changeINV.active" width="600px">
      <v-card
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
        "
      >
        <div style="margin: 10px">
          <table>
            <tr>
              <td>Имя</td>
              <td>
                <textarea
                  name=""
                  id=""
                  cols="50"
                  rows="2"
                  v-model="changeINV.data.name"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>Школа</td>
              <td>
                <select
                  v-model="changeINV.data.school"
                  class="form-select"
                  name=""
                  id=""
                  text="Выбрать школу"
                >
                  <option selected="selected" disabled>
                    {{ changeINV.data.school }}
                  </option>
                  <option value="Старшая">Старшая</option>
                  <option value="Начальная">Начальная</option>
                  <option value="Иное">Иное</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>Нахождение</td>
              <td>
                <textarea
                  name=""
                  id=""
                  cols="50"
                  rows="2"
                  v-model="changeINV.data.location"
                ></textarea>
              </td>
            </tr>

            <tr>
              <td>Инвентарный номер</td>
              <td>
                <textarea
                  name=""
                  id=""
                  cols="50"
                  rows="2"
                  v-model="changeINV.data.schoolID"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>Группа</td>
              <td>
                <textarea
                  name=""
                  id=""
                  cols="50"
                  rows="2"
                  v-model="changeINV.data.group"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>Серийный номер</td>
              <td>
                <textarea
                  name=""
                  id=""
                  cols="50"
                  rows="2"
                  v-model="changeINV.data.serialNumber"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>Количество</td>
              <td>
                <input type="number" v-model="changeINV.data.quantity" />
              </td>
            </tr>
            <tr>
              <td>Федеральная программа</td>
              <td>
                <textarea
                  name=""
                  id=""
                  cols="50"
                  rows="2"
                  v-model="changeINV.data.federalProgram"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>Статус</td>
              <td>
                <select
                  v-model="changeINV.data.status"
                  class="form-select"
                  name=""
                  id=""
                  text="Выбрать новый статус"
                >
                  <option selected="selected" disabled>
                    {{ changeINV.data.status }}
                  </option>
                  <option value="Используется">Используется</option>
                  <option value="Не используется">Не используется</option>
                  <option value="На списание">На списание</option>
                  <option value="Списан">Списан</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>Комментарий</td>
              <td>
                <textarea
                  name=""
                  id=""
                  cols="50"
                  rows="2"
                  v-model="changeINV.data.comment"
                ></textarea>
              </td>
            </tr>
          </table>
        </div>

        <v-card-actions
          style="
            display: flex;
            flex-wrap: wrap;
            width: 600px;
            align-items: center;
            justify-content: center;
          "
        >
          <v-btn
            v-if="selectedClass.classID === 'admin'"
            @click="updateINV()"
            style="margin-top: 10px"
            color="success"
            >Обновить</v-btn
          >
          <v-btn
            style="margin-top: 10px"
            color="primary"
            @click="changeINV.active = false"
            >Отмена</v-btn
          >
        </v-card-actions>

        <VueQRCodeComponent
          style="padding: 10px"
          :text="changeINV.data._id"
          size="150"
          color="#000000"
          bg-color="#ffffff"
          error-level="H"
        ></VueQRCodeComponent>
      </v-card>
    </v-dialog>

    <v-dialog v-model="groupOperationShow.active" width="600px">
      <v-card
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          flex-direction: column;
        "
      >
        <div>Для</div>
        <ul class="list-group">
          <li
            class="list-group-item list-group-item-warning"
            v-for="el in groupOperationShow.list"
            :key="el.name"
          >
            {{ el.name }} ({{ el.schoolID }})
          </li>
        </ul>
        <div>изменить (не изменяйте то, что менять не следует):</div>
        <div style="margin: 10px">
          <table>
            <tr>
              <td>Имя</td>
              <td>
                <textarea
                  name=""
                  id=""
                  cols="50"
                  rows="2"
                  v-model="groupOperationShow.data.name"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>Школа</td>
              <td>
                <select
                  v-model="groupOperationShow.data.school"
                  class="form-select"
                  name=""
                  id=""
                  text="Выбрать школу"
                >
                  <option selected="selected" disabled>
                    {{ groupOperationShow.data.school }}
                  </option>
                  <option value="Старшая">Старшая</option>
                  <option value="Начальная">Начальная</option>
                  <option value="Иное">Иное</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>Нахождение</td>
              <td>
                <textarea
                  name=""
                  id=""
                  cols="50"
                  rows="2"
                  v-model="groupOperationShow.data.location"
                ></textarea>
              </td>
            </tr>

            <tr>
              <td>Инвентарный номер</td>
              <td>
                <textarea
                  name=""
                  id=""
                  cols="50"
                  rows="2"
                  v-model="groupOperationShow.data.schoolID"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>Группа</td>
              <td>
                <textarea
                  name=""
                  id=""
                  cols="50"
                  rows="2"
                  v-model="groupOperationShow.data.group"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>Серийный номер</td>
              <td>
                <textarea
                  name=""
                  id=""
                  cols="50"
                  rows="2"
                  v-model="groupOperationShow.data.serialNumber"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>Количество</td>
              <td>
                <input
                  type="number"
                  v-model="groupOperationShow.data.quantity"
                />
              </td>
            </tr>
            <tr>
              <td>Федеральная программа</td>
              <td>
                <textarea
                  name=""
                  id=""
                  cols="50"
                  rows="2"
                  v-model="groupOperationShow.data.federalProgram"
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>Статус</td>
              <td>
                <select
                  v-model="groupOperationShow.data.status"
                  class="form-select"
                  name=""
                  id=""
                  text="Выбрать новый статус"
                >
                  <option selected="selected" disabled>
                    {{ groupOperationShow.data.status }}
                  </option>
                  <option value="Используется">Используется</option>
                  <option value="Не используется">Не используется</option>
                  <option value="На списание">На списание</option>
                  <option value="Списан">Списан</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>Комментарий</td>
              <td>
                <textarea
                  name=""
                  id=""
                  cols="50"
                  rows="2"
                  v-model="groupOperationShow.data.comment"
                ></textarea>
              </td>
            </tr>
          </table>
        </div>
        <v-card-actions
          style="
            display: flex;
            flex-wrap: wrap;
            width: 600px;
            align-items: center;
            justify-content: center;
          "
        >
          <v-btn
            @click="updateINVGroup()"
            style="margin-top: 10px"
            color="success"
            >Обновить</v-btn
          >
          <v-btn
            style="margin-top: 10px"
            color="primary"
            @click="groupOperationShow.active = false"
            >Отмена</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="infoShow" width="600px">
      <v-card
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          flex-direction: column;
        "
      >
        <div style="padding: 10px">
          <p> <b>Основные сведения</b> </p>
          <p>
            Система <b>"Инвентаризация"</b> предназначена для отслеживания
            состояния инвентаря и оборудования в школе.
          </p>
          <p>
            Позволяет быстро находить имущество по инвентарному номеру или
            другому признаку - местоположение, наименование и т.д. Для поиска
            воспользуйтесь специальной строкой с надписью "Поиск" в нижней
            таблице
          </p>
          <p>
            Обычный пользователь может только просмотреть данные на интересующие объекты (в т.ч. отсканировать QR код с помощью специального сканера, предусмотренного на начальной странице сайта <a href="http://actsch24.ru" target="_blank">ACT</a> (кнопка QR-сканер).
          </p>
          <p><b>Краткий принцип работы системы</b></p>
          <table>
            <tr>
              <td>
                Создается QR код
              </td>
              <td>
               Распечатывается
              </td>
              <td>
              Наклеивается на оборудование или другой объект с помощью прозрачного скотча
              </td>
              <td>
             Сканируется с помощью инструмента на главной странице сайта
              </td>
              <td>
             Заполняются данные на объект
              </td>
            </tr>
          </table>
          <p>
            Порядок заполнения системы:
            <ol type="1">
              <li> <b>Создать QR код</b>  (доступно только для администратора) - необходимо воспользоваться блоком <b> QR коды</b>. Есть возможность создать несколько QR кодов одновременно.</li>
              <li> <b>Печатать QR код</b> (доступно только для администратора) - необходимо воспользоваться блоком <b> QR коды</b>. МОжно напечатать все сразу или только выбранные из списка.</li>
              <li> <b>Наклеить QR код на объект имущества школы</b> - после печать следует наклеить QR с помощью прозрачного скотча. Необходимо, чтобы QR мог считываться камерой мобильного телефона, планшета или Веб-камерой компьютера</li>
              <li> <b>Отсканировать QR код</b> - необходимо воспользоваться специальным сканером, предусмотренным на начальной странице сайта <a href="http://actsch24.ru" target="_blank">ACT</a> (кнопка QR-сканер). После успешного сканирования - заполнить все необходимые данные и нажать кнопку обновить. (можно использовать один QR код на несколько объектов, например - стулья в кабинете. В данных карточки необходимо указать количество объектов с этим QR кодом). Для изменения данных необходимо ввести специальный пароль администратора</li>
              <li><b>Готово!</b> - После обновления статуса QR кода с "Не активен" на другой, в системе сразу появляется запись об объекте и всеми введенными данными. QR код при этом удаляется из списка свободных. Повторная распечатака QR кода возможна (чтобы его увидеть, достаточно нажать на синюю кнокпу "Изменить" в основной таблице с поиском)</li>
            </ol>
          </p>
          <p> <b>Советы</b></p>
          <p>Используйте уникальные названия для кабинетов. Для предметов из одного кабинета - название местонахорждения должно быть одинаковым, учитывая регистр и т.д. (например "Тех. Мальчики" - значит нужно постоянно использовать это название кабинета. "Тех мальчики" - это уже другой кабинет)</p>
        </div>
        <button
          style="margin-left: 7px"
          class="btn btn-danger"
          @click="infoShow = !infoShow"
        >
          Выход
        </button>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TutorialDataService from "../services/TutorialDataService";
import VueQRCodeComponent from "vue-qrcode-component";
import * as XLSX from "xlsx";
export default {
  components: {
    VueQRCodeComponent: VueQRCodeComponent,
  },
  props: {
    selectedClass: Object,
  },
  data() {
    return {
      invList: [],
      infoShow: false,
      selectLocation: "",
      printList: [],
      groupOperationShow: { active: false, data: {} },
      invNotUsedList: [],
      changeINV: { active: false, data: {} },
      loading: [],
      headers: [
        {
          value: "index",
          text: "№",
          width: "20px",
        },
        {
          text: "Наименование",
          value: "name",
          sortable: false,
        },
        {
          text: "Инвентарный номер",
          value: "schoolID",
          sortable: false,
        },
        {
          text: "Нахождение",
          value: "location",
          sortable: false,
        },
        {
          text: "Школа",
          value: "school",
          sortable: false,
        },

        { text: "Группа", value: "group", sortable: false },
        {
          text: "Серийный номер (заводской)",
          value: "serialNumber",
          sortable: false,
        },
        {
          text: "Кол-во",
          value: "quantity",
          sortable: false,
          width: "20px",
        },
        {
          text: "Федеральная программа",
          value: "federalProgram",
          sortable: false,
        },
        {
          text: "Статус",
          value: "status",
          sortable: false,
        },
        { text: "Комментарий", value: "comment", sortable: false },
        {
          text: "Действия",
          value: "actions",
          sortable: false,
        },
      ],
      search: [],
    };
  },
  methods: {
    createINV() {
      let text = prompt(
        "Добавьте комментарий (например для кого делаете QR код):",
        text
      );
      let data = {
        name: "n/a",
        schoolID: "n/a",
        serialNumber: "n/a",
        school: "Старшая",
        group: "n/a",
        quantity: 1,
        used: false,
        location: "n/a",
        federalProgram: "n/a",
        comment: text,
      };
      TutorialDataService.createINV(data)
        .then((response) => {
          console.log("УСПЕШНО ОТПРАВЛЕНО", response);
          this.getINV();
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
    },
    async createManyINV() {
      let text = prompt(
        "Добавьте комментарий (например для кого делаете QR коды):",
        text
      );
      let count = prompt("Сколько нужно создать QR кодов?:", count);
      let data = {
        name: "n/a",
        schoolID: "n/a",
        serialNumber: "n/a",
        comment: text,
        group: "n/a",
        school: "Старшая",
        quantity: 1,
        used: false,
        location: "n/a",
        federalProgram: "n/a",
        status: "Не активен",
      };
      for (let i = 0; i < Number(count); i++) {
        await TutorialDataService.createINV(data)
          .then((response) => {
            console.log("УСПЕШНО ОТПРАВЛЕНО", response);
          })
          .catch((e) => {
            console.log("1111111111", e);
          });
      }
      this.getINV();
    },
    async getINV() {
      this.invList = [];
      this.invNotUsedList = [];
      await TutorialDataService.getAllINV().then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].used) {
            let a = res.data[i];
            a.selectGroupOperation = false;
            this.invList.push(a);
          } else {
            this.invNotUsedList.push(res.data[i]);
          }
        }
        this.$forceUpdate();
      });
    },
    updateINVopen(el) {
      this.changeINV.active = true;
      this.changeINV.data = el;
    },
    async updateINV() {
      let el = this.changeINV.data;
      let data = {
        name: el.name,
        schoolID: el.schoolID,
        serialNumber: el.serialNumber,
        comment: el.comment,
        school: el.school,
        group: el.group,
        quantity: el.quantity,
        location: el.location,
        federalProgram: el.federalProgram,
        status: el.status,
      };

      await TutorialDataService.updateINV(el._id, data)
        .then((response) => {
          console.log("УСПЕШНО ОТПРАВЛЕНО", response);
          this.changeINV.active = false;
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
    },

    async updateINVGroup() {
      let text = "";
      Object.keys(this.groupOperationShow.data).forEach((el) => {
        text += el;
        text += "; ";
      });
      let a = confirm("Для всех позиций будет изменены поля " + text);
      if (a) {
        let countUpdate = 0;
        for (let i = 0; i < this.groupOperationShow.list.length; i++) {
          let data = this.groupOperationShow.data;
          await TutorialDataService.updateINV(
            this.groupOperationShow.list[i]._id,
            data
          )
            .then((response) => {
              console.log("УСПЕШНО ОТПРАВЛЕНО", response);
              countUpdate++;
            })
            .catch((e) => {
              console.log("1111111111", e);
              alert("На " + (i + 1) + " произошла ошибка");
            });
        }

        alert("Выполнено " + countUpdate + " обновлений");
        this.printList = [];
        this.groupOperationShow.data = {};
        this.groupOperationShow.active = !this.groupOperationShow.active;
        await this.getINV();
        this.changeLoc();
      }
    },
    openGroupOperation() {
      this.groupOperationShow.list = [];
      for (let i = 0; i < this.printList.length; i++) {
        if (this.printList[i].selectGroupOperation)
          this.groupOperationShow.list.push(this.printList[i]);
      }
      this.groupOperationShow.data = {};
      this.groupOperationShow.active = !this.groupOperationShow.active;
    },

    updateCheckbox(index) {
      this.printList[index].selectGroupOperation =
        !this.printList[index].selectGroupOperation;
    },
    selectAllGpOperations() {
      this.printList.forEach((el) => {
        el.selectGroupOperation = !el.selectGroupOperation;
      });
    },

    selectQR(el) {
      el.select = !el.select;
      this.$forceUpdate();

      // if (el.target.alt === "Scan me!") {
      //   if (el.target.parentNode.id != "select") {
      //     el.target.parentNode.setAttribute("id", "select");
      //   } else {
      //     el.target.parentNode.setAttribute("id", "");
      //   }
      // } else {
      //   if (el.target.id != "select") {
      //     el.target.setAttribute("id", "select");
      //   } else {
      //     el.target.setAttribute("id", "");
      //   }
      // }
    },
    printTabKab() {
      let top = "<head></head><body onload='window.print()'>";
      let printHtml = document.getElementById("tab_kab").innerHTML; // Получаем содержимое узла для печати

      top +=
        "<table>" +
        printHtml +
        "</table></body><style>TABLE {width: 90%;  border-collapse: collapse; }  TD,TH {padding: 2px; border: 0.5px solid black; }</style>";

      let tab = window.open(
        "",
        "",
        "left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0"
      );
      tab.document.write(top);
      tab.focus();
    },

    printQR() {
      let top = "<head></head><body onload='window.print()'><section>";
      let printHtml = document.getElementById("QR").innerHTML; // Получаем содержимое узла для печати

      top +=
        printHtml +
        "</section><style>section{display: flex;flex-wrap: wrap;}</style>";

      let tab = window.open(
        "",
        "",
        "left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0"
      );
      tab.document.write(top);
      tab.focus();
    },
    printQRselect() {
      let top = "<head></head><body onload='window.print()'><section>";
      let printHtml = "";
      let elements = "";
      this.invNotUsedList.forEach((el) => {
        if (el.select)
          elements +=
            "<div style='padding:10px; border: solid black 1px'>" +
            document.getElementById(el._id).innerHTML +
            "</div>";
      });
      printHtml += elements; // Получаем содержимое узла для печати

      top +=
        printHtml +
        "</section><style>section{display: flex;flex-wrap: wrap;}</style>";

      let tab = window.open(
        "",
        "",
        "left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0"
      );
      tab.document.write(top);
      tab.focus();
    },
    printManyQRselect() {
      let count = prompt(
        "Выбирайте только 1 qr код (например для пометки мебели в кабинете)! Сколько нужно одинаковых?"
      );
      let top = "<head></head><body onload='window.print()'><section>";
      let printHtml = "";
      let elements = "";
      this.invNotUsedList.forEach((el) => {
        if (el.select) {
          for (let i = 0; i < Number(count); i++) {
            elements +=
              "<div style='padding:10px; border: solid black 1px'>" +
              document.getElementById(el._id).innerHTML +
              "</div>";
          }
        }
      });
      printHtml += elements; // Получаем содержимое узла для печати

      top +=
        printHtml +
        "</section><style>section{display: flex;flex-wrap: wrap;}</style>";

      let tab = window.open(
        "",
        "",
        "left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0"
      );
      tab.document.write(top);
      tab.focus();
    },

    async deleteSelect() {
      for (let i = 0; i < this.invNotUsedList.length; i++) {
        if (this.invNotUsedList[i].select) {
          await this.deleteINV(this.invNotUsedList[i]._id, false);
        }
      }
      this.getINV();
    },

    async deleteINV(id, update) {
      let check = false;
      if (update) check = confirm("Вы уверенны?", check);
      else check = true;
      if (check) {
        await TutorialDataService.deleteINV(id)
          .then(() => {
            if (update) {
              this.getINV();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    exportData() {
      // let studentsExports = this.students;
      let data = [
        [
          "№",
          "Наименование",
          "Инвентарный номер",
          "Нахождение",
          "Школа",
          "Группа",
          "Серийный номер (заводской)",
          "Количество",
          "Федеральная программа",
          "Статус",
          "Комментарий",
        ],
      ];
      let str = [];

      for (let i = 0; i < this.invList.length; i++) {
        str.push(i + 1);
        str.push(this.invList[i].name);
        str.push(this.invList[i].schoolID);
        str.push(this.invList[i].location);
        str.push(this.invList[i].school);
        str.push(this.invList[i].group);
        str.push(this.invList[i].serialNumber);
        str.push(this.invList[i].quantity);
        str.push(this.invList[i].federalProgram);
        str.push(this.invList[i].status);
        str.push(this.invList[i].comment);

        data.push(str);
        str = [];
      }
      this.exportXLSX(data);
    },
    exportXLSX(ws_data) {
      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "SheetJS Tutorial",
        Subject: "Test",
        Author: "Red Stapler",
        CreatedDate: new Date(2017, 12, 19),
      };

      wb.SheetNames.push("Test Sheet");
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      wb.Sheets["Test Sheet"] = ws;

      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      this.downloadBlob(
        new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
        "Экспорт данных из ACT.xlsx"
      );
    },
    downloadBlob(content, filename, contentType) {
      //   // Create a blob
      //   var blob = new Blob([content], { type: contentType });
      //   var url = URL.createObjectURL(blob);

      //   // Create a link to download it
      //   var pom = document.createElement("a");
      //   pom.href = url;
      //   pom.setAttribute("download", filename);
      //   pom.click();

      var blob = new Blob([content], { type: contentType });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    changeLoc() {
      // for (let i = 0; i < this.invList.length; i++) {
      //   this.invList[i].selectGroupOperation = false;
      // }
      let tempList = this.invList
        .filter((el) => {
          if (el.location == this.selectLocation.location) return el;
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1));

      if (this.selectLocation == "ВСЕ") {
        this.printList = this.invList.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
      } else {
        this.printList = [];
        for (let i = 0; i < tempList.length; i++) {
          this.printList.push(tempList[i]);
        }
      }
    },
  },
  async mounted() {
    await this.getINV();
  },
  computed: {
    filterByLocation: function () {
      if (!this.invList) return [];
      let tempSet = [];
      this.invList.forEach((element) => {
        let flag = false;
        for (let i = 0; i < tempSet.length; i++) {
          if (
            element.location == tempSet[i].location &&
            element.school == tempSet[i].school
          ) {
            flag = true;
          }
        }
        if (!flag)
          tempSet.push({ location: element.location, school: element.school });
      });
      return [...tempSet].sort((a, b) => (a.location > b.location ? 1 : -1));
    },
  },
};
</script>

<style scoped>
.control-buttons {
  display: flex;
  flex-wrap: wrap;
}
.selectClass {
  background-color: #00abc2;
}
.unselect {
  background-color: #ffffff;
}
TABLE {
  width: 90%; /* Ширина таблицы */
  border-collapse: collapse; /* Убираем двойные линии между ячейками */
}
TD,
TH {
  padding: 2px; /* Поля вокруг содержимого таблицы */
  border: 0.5px solid black; /* Параметры рамки */
}
TH {
  background: #b0e0e6; /* Цвет фона */
  /* position: sticky; */
  top: 0;
  /* z-index: 220; */
}
tr:nth-child(odd) {
  background-color: #ffffff;
  padding: 1px;
}
tr:nth-child(even) {
  background-color: #eeeeee;
  padding: 1px;
}

td {
  background-clip: padding-box;
}
.inv_main_qr_list {
  display: flex;
  flex-wrap: wrap;
}

details > summary {
  padding: 5px;
  background-color: #eee;
  color: #333;
  border: 1px #ccc solid;
  cursor: pointer;
}

details > div {
  border: 1px #ccc solid;
  padding: 10px;
}
details[open] > summary {
  color: #eee;
  background-color: #333;
}

ol {
    list-style: disk;
    list-style-position: inside;
}
li{
  list-style-type:decimal
}
</style>
